import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { appInsights } from "app/appinsights";
import { Subject } from "rxjs";
import { UserHandling } from "scripts/user";

@Injectable({ providedIn: "root" })
export class GlobalErrorHandler implements ErrorHandler {
  errorsToShow$ = new Subject<{ message: string; fatal?: boolean }>();
  debugMode = false;

  constructor(private userHandling: UserHandling) {}

  handleError(e: unknown) {
    if (e instanceof TypeError && e.message.indexOf("ServiceWorker") !== -1) {
      return;
    }

    if (!(e instanceof Error)) {
      try {
        if (typeof e === "string") {
          throw new Error(e);
        }
      } catch (o) {
        e = o;
      }
    }

    const properties: { [name: string]: string } = {};

    if (e instanceof HttpErrorResponse) {
      properties["HttpStatus"] = e.status.toString();
      properties["HttpStatusText"] = e.statusText;
      properties["HttpBody"] = e.error;
    }

    appInsights.trackException({ exception: e as Error, properties });

    this.errorsToShow$.next({ message: (e as any).toString(), fatal: true });
  }

  trackNetworkError(e: unknown) {
    const properties: { [name: string]: string } = {};

    if (e instanceof HttpErrorResponse) {
      properties["HttpStatus"] = e.status.toString();
      properties["HttpStatusText"] = e.statusText;
      properties["HttpBody"] = e.error;
    }

    appInsights.trackException({ exception: e as Error, properties });
  }

  handleFatalNetworkError(e: unknown) {
    this.trackNetworkError(e);
    if (!this.debugMode) {
      this.userHandling.localLogout();
    }
  }
}
