import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DefaultComponent } from "components/default.component";
import { HeaderComponent } from "components/header.component";

@NgModule({
  imports: [HeaderComponent, DefaultComponent],
  exports: [CommonModule, RouterModule, FormsModule, HeaderComponent, DefaultComponent],
})
export class SharedModule {}
