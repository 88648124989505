export function createSingleLock() {
  const queue: ((value?:unknown) => void)[] = [];
  let locked = false;

  return async function execute<T>(fn: () => T) {
    await acquire();
    try {
      return fn();
    } finally {
      release();
    }
  };

  function acquire() {
    if (locked) {
      return new Promise((resolve) => queue.push(resolve));
    } else {
      locked = true;
      return Promise.resolve();
    }
  }

  function release() {
    const next = queue.shift();
    if (next) {
      next();
    } else {
      locked = false;
    }
  }
}
