import { appInsights } from "app/appinsights";
import { MobilDentApi } from "./mobilDentApi";
import { Recommendation } from "entities/recommendation";
import { db } from "./db";
import { educationTransmissionTimerId, screeningTransmissionTimerId } from "./syncActivity";
import { Inject, Injectable } from "@angular/core";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { firstValueFrom } from "rxjs";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class UserHandling {
  readonly userAgentApplicationPromise: Promise<AccountInfo | null>;

  constructor(
    private mobilDentApi: MobilDentApi,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
  ) {
    /*msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      first()
    )*/

    this.userAgentApplicationPromise = (async () => {
      console.info("userAgentApplicationPromise running");

      try {
        await firstValueFrom(msalService.initialize());
        let authResult = await firstValueFrom(msalService.handleRedirectObservable());
        if (authResult) {
          this.setUser(authResult);
          void this.loggedIn();
        } else {
          authResult = await firstValueFrom(msalService.acquireTokenSilent({ scopes: ["user.read"] }));
          this.setUser(authResult);
        }

        return authResult.account;
      } catch (error) {
        await this.localLogout();
        return null;
      }
    })();
  }

  setUser(authResult: AuthenticationResult) {
    const user = authResult.account;
    if (user) {
      this.msalService.instance.setActiveAccount(user);
      console.info("logged in as", user?.name, user?.username);
      appInsights.setAuthenticatedUserContext(user.name!);
    }
  }

  async loggedIn() {
    this.mobilDentApi.syncSessionId = null; // In case it's left from a previous execution.
    sessionStorage.removeItem("activityId");
    try {
      const recommendations = await this.mobilDentApi.loadRecommendations();
      await db.transaction("rw", db.recommendations, async () => {
        await db.recommendations.clear();
        for (const rec of recommendations) {
          const r: Recommendation = {
            orderNumber: rec.orderNumber,
            recommendationText: rec.recommendationText,
            chosen: false,
          };
          await db.recommendations.add(r);
        }
      });
    } catch (error) {
      if ((await db.recommendations.count()) == 0) {
        throw error;
      }
    }

    await this.router.navigate(["/states"]);
  }

  async login() {
    localStorage.removeItem("token");
    await firstValueFrom(this.msalService.loginRedirect({ scopes: ["user.read"], prompt: "select_account", redirectUri: location.origin }));
  }

  async initialized() {
    await this.userAgentApplicationPromise;
  }

  async getAccount() {
    return await this.userAgentApplicationPromise;
  }

  async refreshLogin() {
    await this.userAgentApplicationPromise;
    await firstValueFrom(this.msalService.loginRedirect({ scopes: ["user.read"] }));
  }

  async logout() {
    await this.userAgentApplicationPromise;
    console.info("logout");
    stopTimers();
    appInsights.clearAuthenticatedUserContext();
    await firstValueFrom(this.msalService.logout());
  }

  async localLogout() {
    stopTimers();
    appInsights.clearAuthenticatedUserContext();
    await this.router.navigate(["/login"]);
  }
}

function stopTimers() {
  if (educationTransmissionTimerId !== null) {
    clearTimeout(educationTransmissionTimerId);
    localStorage.removeItem("educationTransmissionTimerActive");
  }
  if (screeningTransmissionTimerId !== null) {
    clearTimeout(screeningTransmissionTimerId);
    localStorage.removeItem("screeningTransmissionTimerActive");
  }
}
