import { HttpClientModule, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, Injectable, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { GlobalErrorHandler } from "error";
import { Observable, switchMap } from "rxjs";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ErrorModalComponent } from "components/error-modal.component";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.msalService.acquireTokenSilent({ scopes: ["user.read"] }).pipe(
      switchMap((token) => {
        if (token.idToken) {
          req = req.clone({ headers: req.headers.set("Authorization", "Bearer " + token.idToken) });
        }
        return next.handle(req);
      }),
    );
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    NgbModule,
    ErrorModalComponent,
  ],
  providers: [
    { provide: ErrorHandler, useExisting: GlobalErrorHandler },
    { provide: "BASE_API_URL", useValue: "/api/" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    MsalService,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
