import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  standalone: true,
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Oral Care Munhälsobedömning</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">Stäng</button>
    </div>
  `,
})
export class ErrorModalComponent {
  @Input() message = "";

  constructor(public activeModal: NgbActiveModal) {}
}
