import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="row">
      <div class="col-2"><img src="assets/icon.png" height="50" width="50" style="margin: 5px" /></div>
      <div class="col-10" style="text-align: center">
        <h2 style="margin: 13px 0">{{ title }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12"><hr /></div>
    </div>
  `,
  styles: [
    `
      hr {
        margin: 0 auto;
      }
    `,
  ],
})
export class HeaderComponent {
  @Input() title = "";

  constructor() {}
}
