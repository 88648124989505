import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserHandling } from "scripts/user";
import { SharedModule } from "shared.module";

@Component({
  standalone: true,
  imports: [SharedModule],
  template: `<default-component></default-component>`,
})
export class StartPageComponent implements OnInit {
  public constructor(private userHandling: UserHandling, private router: Router) {}
  ngOnInit(): void {
    this.userHandling.getAccount().then((account) => {
      if (account) {
        this.router.navigate(["/states"]);
      }
    });
  }
}
