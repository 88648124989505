import Dexie from "dexie";
import { Activity } from "entities/activity";
import { Screening, TransferState } from "entities/screening";
import { Patient } from "entities/patient";
import { Recommendation } from "entities/recommendation";
import { ToothInfo } from "../entities/toothInfo";
import { ToothStatus } from "../common";

class Db extends Dexie {
  // Set name for store to "ocScreening" for production.
  // Other names, i.e "ocScreeningDemonn" can be used during development and for test
  constructor() {
    super("ocScreeningDemo8");
    this.version(1).stores({
      activity: "id, date, unitId",
      patient: "id, departmentId, changed",
      screening: "++id, activityId, patientId, unitId",
      recommendations: "++id",
    });
    this.version(2).stores({
      patient: "id, departmentId, changed, activityId, [changed+activityId]",
      screening: "++id, activityId, patientId, unitId, [patientId+activityId]",
    });
    this.version(3).stores({
      patient: "id, departmentId, activityId",
    });
    this.version(4).upgrade((trans) => {
      void trans
        .table("screening")
        .toCollection()
        .modify((screening: Screening) => {
          const toothInfos: ToothInfo[] = screening.toothInfos as unknown as ToothInfo[];
          const initial: { [toothNumber: string]: ToothStatus } = {};
          const transformed = toothInfos.reduce((o, toothInfo) => {
            o[toothInfo.toothNumber.toString()] = toothInfo.toothStatus;
            return o;
          }, initial);
          screening.toothInfos = transformed;
        });
    });
  }

  activity!: Dexie.Table<Activity, number>;
  patient!: Dexie.Table<Patient, number>;
  screening!: Dexie.Table<Screening, number>;
  recommendations!: Dexie.Table<Recommendation, number>;
}

export const db = new Db();
