export function parseNullableInt(s: string | null): number | null {
  return s != null ? parseInt(s) : null;
}

export const enum Jaw {
  Upper = 1,
  Lower = 2,
}

export const enum JawStatus {
  DentureComplete = 1,
  DenturePartly = 2,
  OwnTeeth = 3,
  RestsOfRoot = 4,
  Toothless = 5,
  Bridges = 6,
  Implants = 7,
}

export const enum ToothStatus {
  Missing = 1,
  RestsOfRoot = 2,
  OwnTooth = 3,
  Detachable = 4,
  BridgeOrImplant = 5,
}

export const enum AnamnesisAbilityToChew {
  Good = 1,
  BadTeethCause = 2,
  BadOtherCause = 3,
}

export const enum AnamnesisDryMouthType {
  NotDry = 0,
  SlightlyDry = 1,
  VeryDry = 2,
  Unknown = 42,
}

export const enum AnamnesisMouthHygiene {
  Good = 1,
  Fair = 2,
  Bad = 3,
  Inferior = 4,
}

export const enum AnamnesisPain {
  No = 1,
  CouldNotBeVerified = 2,
  YesTeethCause = 3,
  YesNotTeethCause = 4,
}

export const enum TreatmentType {
  None = 0,
  ByOralCare = 1,
  Personal = 2,
  NoInfo = 3,
}

export const enum ScreeningStatus {
  Performed = 1,
  Declined = 2,
  NotHome = 3,
  SeriouslyIll = 4,
  MovedAway = 5,
  Deceased = 6,
  Other = 7,
}

export const enum DeregistrationReason {
  Deceased = 1,
  OtherCause = 2,
  NotCorrect = 3,
}

export const enum ActivityType {
  None = 0,
  Screening = 1,
  EducationBasic = 2,
  EducationStep2 = 3,
}

export const enum PatientCategory {
  EjAngivet = 0,
  MunicipalAccomodation = 1,
  MedicalSupervisionAtHome = 2,
  CareProgram = 3,
  OwnLiving = 4,
}

export const enum DentistCariesTreatmentNeedType {
  None = 0,
  Yes = 1,
  Urgent = 2,
}
export const enum DentistDentureTreatmentNeedType {
  None = 0,
  Yes = 1,
  Urgent = 2,
}
export const enum DentistMouthMembraneTreatmentNeedType {
  None = 0,
  Yes = 1,
  Urgent = 2,
}

export const enum CountyCouncilId {
  VästraGötalandsRegionen = 1,
  Värmland = 2,
  Östergötland = 3,
  Blekinge = 4,
  Sörmland = 7,
  StockholmsLänsLandsting = 9,
  RegionSkåne = 2880,
  Halland = 2881,
  Västerbotten = 10,
  Norrbotten = 11,
  Uppsala = 2885,
  Germany = 2886,
  Jönköping = 2887,
}

export let offlinePluginRuntimeInstalled = false;
export function setOfflinePluginRuntimeInstalled() {
  offlinePluginRuntimeInstalled = true;
}
