import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "default-component",
  imports: [CommonModule],
  template: `
    <div class="row">
      <div class="col-12 text-center"><img src="/assets/images/logga-black.png" width="120" height="120" style="margin: 10px" /></div>
    </div>
    <div class="row">
      <div class="col-12 text-center"><h1>MobilDent</h1></div>
    </div>
  `,
})
export class DefaultComponent {}
