import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StartPageComponent } from "components/start-page.component";

const routes: Routes = [
  { path: "", pathMatch: "full", component: StartPageComponent },
  { path: "login", loadComponent: () => import("components/login-page/login-page").then((m) => m.LoginPage) },
  { path: "states", loadComponent: () => import("components/states-page/states-page").then((m) => m.StatesPage) },
  { path: "areas", loadComponent: () => import("components/areas-page/areas-page").then((m) => m.AreasPage) },
  { path: "units", loadComponent: () => import("components/units-page/units-page").then((m) => m.UnitsPage) },
  { path: "report", loadComponent: () => import("components/report-page/report-page").then((m) => m.ReportPage) },
  { path: "departments", loadComponent: () => import("components/departments-page/departments-page").then((m) => m.DepartmentsPage) },
  { path: "activities", loadComponent: () => import("components/activities-page/activities-page").then((m) => m.ActivitiesPage) },
  { path: "educations", loadComponent: () => import("components/educations-page/educations-page").then((m) => m.EducationsPage) },
  { path: "patients", loadComponent: () => import("components/patients-page/patients-page").then((m) => m.PatientsPage) },
  { path: "syncing", loadComponent: () => import("components/syncing-page/syncing-page").then((m) => m.SyncingPage) },
  { path: "advanced", loadComponent: () => import("components/advanced-page/advanced-page").then((m) => m.AdvancedPage) },
  {
    path: "screening",
    loadComponent: () => import("components/screening-page/screening-page").then((m) => m.ScreeningPage),
    title: "Munhälsobedömning",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
