import { Component } from "@angular/core";
import { Router, NavigationEnd, EventType } from "@angular/router";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ErrorModalComponent } from "components/error-modal.component";
import { GlobalErrorHandler } from "error";
import { tap, filter, map, first } from "rxjs";
import { SyncActivity } from "scripts/syncActivity";
import { UserHandling } from "scripts/user";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "mobildent";

  constructor(
    swUpdate: SwUpdate,
    router: Router,
    syncActivity: SyncActivity,
    userHandling: UserHandling,
    modalService: NgbModal,
    errorHandler: GlobalErrorHandler,
  ) {
    const navigated$ = router.events.pipe(
      //tap((e) => console.log(e)),
      filter((evt) => (evt as NavigationEnd).type === EventType.NavigationEnd),
      map((evt) => evt as NavigationEnd),
    );

    if (swUpdate.isEnabled) {
      navigated$.subscribe(async () => {
        try {
          const updateFound = await swUpdate.checkForUpdate();
          if (updateFound) {
            console.log("A new version is available.");
          }
        } catch (err) {
          console.error("Failed to check for updates:", err);
        }
      });

      swUpdate.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY")).subscribe((evt) => {
        navigated$.pipe(first()).subscribe(() => document.location.reload());
      });
    }

    let screeningTransmissionTimerActive = localStorage.getItem("screeningTransmissionTimerActive");
    let educationTransmissionTimerActive = localStorage.getItem("educationTransmissionTimerActive");
    userHandling.getAccount().then((account) => {
      if (account) {
        if (screeningTransmissionTimerActive !== null) {
          void syncActivity.sendNonSentScreeningsWithTimer();
        }
        if (educationTransmissionTimerActive !== null) {
          void syncActivity.sendActivitiesInBackground();
        }
      }
    });

    errorHandler.errorsToShow$.subscribe(async (error) => {
      const modalRef = modalService.open(ErrorModalComponent, { centered: true });
      modalRef.componentInstance.message = error.message;
      await modalRef.result.catch(() => {});
      if (error.fatal && !errorHandler.debugMode) {
        userHandling.localLogout();
      }
    });
  }
}
