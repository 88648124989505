import { db } from "./db";
import { SyncScreening, SyncScreeningInfo, SyncPerformedScreening, SyncDeviationInfo, SyncEducationActivity } from "entities/sync";
import { ScreeningStatus } from "common";
import { Recommendation } from "entities/recommendation";
import { Screening, TransferState } from "../entities/screening";
import { createSingleLock } from "./promise-lock";
//import { showError } from '../error';
import { Activity } from "../entities/activity";
import { Injectable } from "@angular/core";
import { MobilDentApi } from "./mobilDentApi";
import { HttpErrorResponse } from "@angular/common/http";
import { GlobalErrorHandler } from "error";

const syncingLock = createSingleLock();
export let screeningTransmissionTimerId: number | null = null;
export let educationTransmissionTimerId: number | null = null;

@Injectable({ providedIn: "root" })
export class SyncActivity {
  constructor(private mobilDentApi: MobilDentApi, private errorHandler: GlobalErrorHandler) {}

  public async sendNonSentScreeningsWithoutTimer() {
    await syncingLock(async () => {
      const screenings = await db.screening.toArray();
      if (screeningTransmissionTimerId !== null) {
        clearTimeout(screeningTransmissionTimerId);
        screeningTransmissionTimerId = null;
        localStorage.removeItem("screeningTransmissionTimerActive");
      }

      for (let screening of screenings) {
        if (screening.transferState !== TransferState.SentToServer) {
          //Catch undefined belonging to screenings from the old system.
          let syncScreening = this.createSyncScreening(screening);
          try {
            await this.mobilDentApi.writeSyncScreening(screening.activityId, syncScreening);
            //Response was ok.
            await db.screening.update(screening.id, { transferState: TransferState.SentToServer });
          } catch (error) {
            if (error instanceof HttpErrorResponse && error.status && error.status <= 500) {
              await db.screening.update(screening.id, { transferState: TransferState.Failed });
            } else {
              throw error;
            }
          }
        }
      }
    });
  }

  public async sendRemainingScreeningsFromOtherDepartmentIfExistWithoutUsingTimer(chosenDepartmentId: number) {
    await syncingLock(async () => {
      const screenings = await db.screening.toArray();
      if (screeningTransmissionTimerId !== null) {
        clearTimeout(screeningTransmissionTimerId);
        screeningTransmissionTimerId = null;
        localStorage.removeItem("screeningTransmissionTimerActive");
      }

      for (let screening of screenings) {
        if (screening.transferState === TransferState.NotSentToServer && screening.departmentId !== chosenDepartmentId) {
          let syncScreening = this.createSyncScreening(screening);
          try {
            await this.mobilDentApi.writeSyncScreening(screening.activityId, syncScreening);
            //Response was ok.
            await db.screening.update(screening.id, { transferState: TransferState.SentToServer });
          } catch (error) {
            if (error instanceof HttpErrorResponse && error.status && error.status <= 500) {
              await db.screening.update(screening.id, { transferState: TransferState.Failed });
            } else {
              throw error;
            }
          }
        }
      }
    });
  }

  public async sendNonSentScreeningsWithTimer() {
    const screenings = await db.screening.toArray();
    await this.sendScreeningsToServer(screenings);
  }

  private async sendScreeningsToServer(screenings: Screening[]) {
    try {
      await syncingLock(async () => {
        if (screeningTransmissionTimerId !== null) {
          clearTimeout(screeningTransmissionTimerId);
          screeningTransmissionTimerId = null;
          localStorage.removeItem("screeningTransmissionTimerActive");
        }

        for (let screening of screenings) {
          if (screening.transferState !== TransferState.SentToServer && screening.transferState !== TransferState.Failed) {
            let syncScreening = this.createSyncScreening(screening);
            try {
              await this.mobilDentApi.writeSyncScreening(screening.activityId, syncScreening);
              await db.screening.update(screening.id, { transferState: TransferState.SentToServer });
            } catch (error) {
              if (error instanceof HttpErrorResponse && error.status && error.status <= 500) {
                await db.screening.update(screening.id, { transferState: TransferState.Failed });
              } else {
                throw error;
              }
            }
          }
        }
      });
    } catch (error) {
      if (error instanceof HttpErrorResponse && (!error.status || error.status > 500)) {
        let newScreeningTransmissionTimerId = window.setTimeout((): void => void this.sendScreeningsToServer(screenings), 20000);
        screeningTransmissionTimerId = newScreeningTransmissionTimerId;
        localStorage.setItem("screeningTransmissionTimerActive", "true");
      } else {
        throw error;
      }
    }
  }

  public async handleWriteSyncScreeningResponseError(message: string) {
    this.errorHandler.errorsToShow$.next({ message });
  }

  private createSyncScreening(screening: Screening) {
    const syncScreening: SyncScreening = {
      activityId: screening.activityId,
      screeningId: screening.id,
      patientId: screening.patientId,
      screeningStatus: screening.screeningStatus,
      syncScreeningInfo: null,
      syncJawInfo: null,
      syncToothInfo: null,
      syncScreeningRecommendation: null,
      syncDeviationInfo: null,
    };
    if (syncScreening.screeningStatus === ScreeningStatus.Performed) {
      if (screening.assistingStaffPresent) {
        if ((screening.assistingStaff || "").length === 0) {
          screening.assistingStaff = "Medföljande personal finns";
        }
      } else {
        screening.assistingStaff = "";
      }
      const si: SyncScreeningInfo = {
        assistingStaff: screening.assistingStaff,
        assistingStaffNotes: screening.assistingStaffNotes,
        anamnesisMouthHygiene: screening.anamnesisMouthHygiene,
        anamnesisMouthMembraneChange: screening.anamnesisMouthMembraneChange,
        anamnesisRed: screening.anamnesisRed,
        anamnesisWhite: screening.anamnesisWhite,
        anamnesisWound: screening.anamnesisWound,
        anamnesisDryMouth: screening.anamnesisDryMouth,
        anamnesisPain: screening.anamnesisPain,
        anamnesisAbilityToChew: screening.anamnesisAbilityToChew,
        needsSupervision: screening.needsSupervision,
        getsSupervisionToday: screening.getsSupervisionToday,
        dentistNeed: screening.dentistNeed,
        dentistUrgent: screening.dentistUrgent,
        dentistCariesGroup: screening.dentistCariesGroup,
        dentistLooseBridge: screening.dentistLooseBridge,
        dentistCaries: screening.dentistCaries,
        dentistFracture: screening.dentistFracture,
        dentistLostFilling: screening.dentistLostFilling,
        dentistExamination: screening.dentistExamination,
        dentistDispatchableDenture: screening.dentistDispatchableDenture,
        dentistBrokenDenture: screening.dentistBrokenDenture,
        dentistBadFitting: screening.dentistBadFitting,
        dentistNewDenture: screening.dentistNewDenture,
        dentistDentureExamination: screening.dentistDentureExamination,
        dentistMouthMembraneChange: screening.dentistMouthMembraneChange,
        dentistRed: screening.dentistRed,
        dentistWhite: screening.dentistWhite,
        dentistWound: screening.dentistWound,
        dentistMouthMembraneExamination: screening.dentistMouthMembraneExamination,
        dentistOtherCause: screening.dentistOtherCause,
        dentistWishes: screening.dentistWishes,
        dentistType: screening.dentistType,
        referral: screening.referral,
        dentistName: screening.dentistName || "",
        dentistClinicPhone: screening.dentistClinicPhone || "",
        hygienistNeed: screening.hygienistNeed,
        hygienistTartar: screening.hygienistTartar,
        hygienistParodontit: screening.hygienistParodontit,
        hygienistGingivit: screening.hygienistGingivit,
        hygienistExamination: screening.hygienistExamination,
        hygienistOtherCause: screening.hygienistOtherCause,
        hygienistWishes: screening.hygienistWishes,
        hygienistName: screening.hygienistName || "",
        hygienistClinicPhone: screening.hygienistClinicPhone || "",
        hygienistType: screening.hygienistType,
        otherRecommendation: screening.otherRecommendation || "",
        hygienistParodontalTreatment: screening.hygienistParodontalTreatment,
        numberOfImplantScrewsUpperJaw: screening.numberOfImplantScrewsUpperJaw,
        numberOfImplantScrewsLowerJaw: screening.numberOfImplantScrewsLowerJaw,
        anamnesisDryMouthType: screening.anamnesisDryMouthType,
        dentistCariesTreatmentNeed: screening.dentistCariesTreatmentNeed,
        dentistDentureTreatmentNeed: screening.dentistDentureTreatmentNeed,
        dentistMouthMembraneTreatmentNeed: screening.dentistMouthMembraneTreatmentNeed,
        hygienistNeedUrgent: screening.hygienistNeedUrgent,
      };

      const performed: SyncPerformedScreening = Object.assign(syncScreening, {
        syncScreeningInfo: si,
        syncJawInfo: [],
        syncToothInfo: [],
        syncScreeningRecommendation: [],
      });

      if (screening.jawInfos) {
        for (const j of screening.jawInfos) {
          const ji = { jaw: j.jaw, jawStatus: j.jawStatus };
          performed.syncJawInfo.push(ji);
        }
      }
      if (screening.toothInfos) {
        for (const [toothNumber, toothStatus] of Object.entries(screening.toothInfos)) {
          const ti = { toothNumber: parseInt(toothNumber), toothStatus: toothStatus };
          performed.syncToothInfo.push(ti);
        }
      }
      if (screening.recommendations) {
        performed.syncScreeningRecommendation.push(
          ...new Map(screening.recommendations.filter((r) => r.chosen).map((r) => <[number, Recommendation]>[r.orderNumber, r])).values(),
        );
      }
      return performed;
    } else {
      Object.assign(syncScreening, {
        syncDeviationInfo: <SyncDeviationInfo>{
          deliveredByName: screening.noThanksDeliveredBy,
          deliveredByPhoneNumber: screening.noThanksDeliveredByPhoneNumber,
          notes: screening.deviationNotes,
        },
      });
      return syncScreening;
    }
  }

  public sendActivitiesInBackground() {
    void (async () => {
      try {
        await this.sendActivities();
      } catch (error) {
        if (error instanceof HttpErrorResponse && (!error.status || error.status > 500)) {
          educationTransmissionTimerId = window.setTimeout(() => this.sendActivitiesInBackground(), 20000);
          localStorage.setItem("educationTransmissionTimerActive", "true");
        } else {
          throw error;
        }
      }
    })();
  }

  public async sendRemainingEducationFromOtherDepartmentIfExistsWithoutUsingTimer(chosenDepartmentId: number) {
    const exists = (await db.activity.toArray()).some((a) => a.departmentId !== chosenDepartmentId);
    if (exists) {
      await this.sendActivities();
    }
  }

  public async sendActivities() {
    return await syncingLock(async () => {
      if (educationTransmissionTimerId !== null) {
        clearTimeout(educationTransmissionTimerId);
        educationTransmissionTimerId = null;
        localStorage.removeItem("educationTransmissionTimerActive");
      }

      const activities = await db.activity.toArray();

      for (const activity of activities) {
        if (!activity.sentToServer) {
          let activityFromServer: SyncEducationActivity | null;

          if (activity.id === -1) {
            // The education activity is newly created.
            activityFromServer = await this.mobilDentApi.getSyncEducation(activity.departmentId); // Check if someone also created a education activity for the same department.
            if (!activityFromServer) {
              //POST.
              try {
                const newActivityId = await this.mobilDentApi.postSyncActivity(activity);
                // Education activity does NOT exist within session.
                await db.activity.update(activity.id, { id: newActivityId, sentToServer: true });
              } catch (error) {
                if (error instanceof HttpErrorResponse && error.status && error.status <= 500) {
                  await db.activity.delete(-1);
                }
                throw error;
              }
            } else {
              // Someone else created an education activity for the same department.
              //PUT.
              const serverActivityId = activityFromServer.id!;
              activity.id = serverActivityId;
              await this.mobilDentApi.putSyncActivity(activity);
              await db.activity.update(-1, { id: serverActivityId, sentToServer: true });
            }
          } else {
            //PUT.
            await this.mobilDentApi.putSyncActivity(activity);
            await db.activity.update(activity.id!, { sentToServer: true });
          }
        }
      }

      return activities;
    });
  }
}
