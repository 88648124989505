/// <reference types="@angular/localize" />

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { MSAL_INSTANCE } from "@azure/msal-angular";
import { BrowserCacheLocation, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AuthConfig } from "scripts/mobilDentApi";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

async function init() {
  const authConfigResponse = await fetch("api/auth/config");
  const authConfig = (await authConfigResponse.json()) as AuthConfig;

  var publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: authConfig.clientId,
      authority: `https://login.windows.net/${authConfig.directoryId}`,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });

  platformBrowserDynamic([{ provide: MSAL_INSTANCE, useValue: publicClientApplication }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

init();
